import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import * as moment from 'moment'

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug:false,
		fallbackLng:'ko',
		interpolation:{
			escapeValue:false
		},
		supportedLngs:['ko', 'en'],
		resources:{
			ko:{
				translation:{
					common:{
						year: "년",
						month: "월",
						select_default:"선택",
						label_unknown:'미확인',
						label_male:'남성',
						label_female:'여성',
						label_age_0:'10대 미만',
						label_age_10:'10대',
						label_age_20:'20대',
						label_age_30:'30대',
						label_age_40:'40대',
						label_age_50:'50대',
						label_age_60:'60대 이상',
						person_count_suffix:'명',
						label_asc:'오름차순',
						label_desc:'내림차순',
						label_unit:'단위',
						minute_suffix:'분',
						current_time:'현재 시간',
						last_update_time:'마지막 업데이트 시간',
						label_prev:'이전',
						label_next:'다음',
						period_analysis:'기간분석',
						compare_analysis:'비교분석',
						period_daily:'일',
						period_weekly:'주',
						period_monthly:'월',
						label_sum:'합계',
						label_avg:'평균',
						label_mon:'월',
						label_tue:'화',
						label_wed:'수',
						label_thu:'목',
						label_fri:'금',
						label_sat:'토',
						label_sun:'일',
						label_full_mon:'월요일',
						label_full_tue:'화요일',
						label_full_wed:'수요일',
						label_full_thu:'목요일',
						label_full_fri:'금요일',
						label_full_sat:'토요일',
						label_full_sun:'일요일',
						logout:'로그아웃',
						월:'월',
						화:'화',
						수:'수',
						목:'목',
						금:'금',
						토:'토',
						일:'일',
						월_full:'월요일',
						화_full:'화요일',
						수_full:'수요일',
						목_full:'목요일',
						금_full:'금요일',
						토_full:'토요일',
						일_full:'일요일',
						월요일:'월요일',
						화요일:'화요일',
						수요일:'수요일',
						목요일:'목요일',
						금요일:'금요일',
						토요일:'토요일',
						일요일:'일요일',
						label_white:'백인',
						label_black:'아프리카인',
						label_latino_hispanic:'라틴&히스패닉',
						label_east_asian:'동아시아인',
						label_southeast_asian:'동남아시아인',
						label_indian:'인도인',
						label_middle_eastern:'중동인',
						label_sum:'합계',
						label_avg:'평균',
						all:'전체'
					},
					navigation:{
						all:'전체',
						dashboard:'대시보드',
						customer_header:'유입고객분석',
						customer_header_heritage:'유입방문객분석',
						store_inflow:'매장 유입 분석',
						store_inflow_heritage:'장소 유입 분석',
						customer_dist:'고객 분포',
						heritage_visitors:'방문객 관리',
						promotion_header:'이벤트분석',
						promotion_analysis:'프로모션 성과 분석',
						settings_header:'설정',
						general_setting:'일반설정',
						area_setting:'매대 영역 설정',
						promotion_manager:'프로모션 설정',
						reports_header:'리포트',
						reports_title:'최근 리포트',
						reports_print:'리포트',
						reports_print_week:'주간 리포트',
						reports_print_month:'월간 리포트',
						store_analysis_header:'매장분석',
						counter_analysis:'매대 분석',
						flow_analysis:'동선 분석',
						heatmap:'히트맵 분석',
						promotion_register:'프로모션 등록',
						promotion_detail:'프로모션 상세보기'
					},
					search_filter:{
						label_period:'기간',
						yesterday:'어제',
						last_week:'지난 7일',
						last_month:'지난 30일',
						last_two_month:'지난 60일',
						last_quarter:'지난 90일',
						label_custom_period:'맞춤 기간',
						all:'전체',
						label_custom:'맞춤',
						label_filter:'필터',
						label_gender:'성별',
						label_age:'연령',
						label_compare_period:'이전 기간',
					},
					dashboard:{
						summary:{
							badge_visiting:'방문고객',
							badge_purchasing:'구매고객',
							badge_target:'타겟',
							badge_duration:'체류시간',
							badge_purchase:'구매',
							badge_category:'카테고리',
						},
						admin_visit_daily:{
							card_title:'총 유입 현황',
							card_tooltip:'오늘 방문 고객과 최근 7일의 방문 고객 추이를 볼 수 있습니다.',
							compare_yesterday:'전일대비',
							compare_last_week:'지난주 동요일 대비',
							label_visit_person:'방문고객',
							label_last_week_avg_visit_person:'지난주 평균 방문고객'
						},
						admin_visit_hourly:{
							card_title:'시간별 전체 유입 현황',
							card_tooltip:'실시간으로 오늘 방문하는 전체 매장의 방문 고객 수를 볼 수 있습니다.',
							compare_yesterday:'전일대비',
							compare_last_week:'지난주 동요일 대비',
							in_calculating:'집계중',
							label_today:'오늘',
							label_last_week:'지난주 동요일',
							label_yesterday_avg_visit_person:'어제 평균 방문고객'
						},
						admin_visit_by_store:{
							tab_foot_traffic:'유동인구',
							tab_visiting:'방문고객',
							tab_purchasing:'구매고객',
							tab_churning:'이탈고객',
							card_tooltip:'전체 매장의 유동인구-방문고객-구매고객-이탈고객의 순위를 오름차순-내림차순으로 볼 수 있습니다.',
							card_title_lite:'실시간 매장별 방문고객 현황',
							card_title_heritage:'실시간 장소별 방문객 현황',
							label_avg:'평균',
							label_visitor:'방문객',
						},
						admin_visit_age_gender:{
							card_title:'전체 성별-연령 분포',
						},
						admin_visit_gender:{
							card_title:'전체 성별 분석',
							card_tooltip:'오늘 전체 매장 방문 고객 성별을 백분율로 볼 수 있습니다.',
						},
						admin_visit_age:{
							card_title:'전체 연령 분석',
							card_tooltip:'오늘 전체 매장 방문 고객 연령을 백분율로 볼 수 있습니다.',
						},
						admin_visit_age_gender_by_store:{
							tab_visiting:'방문고객',
							tab_purchasing:'구매고객',
							tab_churning:'이탈고객',
							card_title:'실시간 매장별 성별 - 연령 분석',
							card_tooltip:'각 지점별 방문고객의 성별 - 연령을 오름차순 - 내림차순으로 볼 수 있습니다. 그래프를 클릭시, 우측 그래프에서 해당 매장의 성별 - 연령을 오름차순으로 볼 수 있습니다.',
							card_title_sub:'매장 상세 성별 - 연령 분포',
							card_tooltip_sub:'매장의 성별 - 연령을 오름차순으로 볼 수 있습니다.',
							card_title_lite:'실시간 매장별 성별 - 연령 분석',
							card_title_heritage:'장소별 성별 - 연령 분석',
							card_title_sub_lite:'매장 상세 성별 - 연령 분포',
							card_title_sub_heritage:'장소 상세 성별 - 연령 분포',
						},
						admin_visit_gender_by_store:{
							card_title:'매장별 성별 분석',
							card_title_heritage:'장소별 성별 분석',
							card_tooltip:'각 지점별 방문 고객의 성별을 오름차순 - 내림차순으로 볼 수 있습니다.'
						},
						admin_visit_age_by_store:{
							card_title:'매장별 연령 분석',
							card_title_heritage:'장소별 연령 분석',
							card_tooltip:'각 지점별 방문 고객의 연령을 오름차순 - 내림차순으로 볼 수 있습니다.'
						},
						admin_duration_daily:{
							last_week_avg_duration:'지난주 평균 체류시간',
							card_title:'전체 체류시간',
							card_tooltip:'전체 매장에서의 고객들의 체류시간을 볼 수 있습니다.',
						},
						admin_duration_gender:{
							last_week_avg_duration:'지난주 평균 체류시간',
							card_title:'성별 체류시간',
							card_tooltip:'성별에 따른 체류시간을 볼 수 있습니다.',
						},
						admin_duration_age:{
							last_week_avg_duration:'지난주 평균 체류시간',
							card_title:'연령별 체류시간',
							card_tooltip:'연령에 따른 체류시간을 볼 수 있습니다.',
						},
						admin_duration_by_store:{
							yesterday_avg_duration:'어제 평균 체류시간',
							card_title:'매장별 체류시간',
							card_tooltip:'매장별 체류시간을 오름차순 - 내림차순으로 볼 수 있습니다.',
						},
						admin_duration_gender_by_store:{
							duration:'체류시간',
							card_title:'매장별 - 성별 체류시간',
							card_tooltip:'매장별 - 성별 체류시간을 오름차순 - 내림차순으로 볼 수 있습니다.',
						},
						store_visit_hourly:{
							tab_foot_traffic:'유동인구',
							tab_visiting:'방문고객',
							tab_purchasing:'구매고객',
							tab_churning:'이탈고객',
							yesterday_avg:'어제 평균',
							label_today:'오늘',
							label_last_week:'지난주 동요일',
							month_avg:'개월 평균',
							in_calculating:'집계중',
							card_tooltip:'상단의 버튼을 클릭하여 유동인구 - 방문고객 - 구매고객 - 이탈고객 현황을 실시간으로 볼 수 있습니다.',
							compare_yesterday:'전일대비',
							compare_last_week:'지난주 동요일 대비',
							realtime:'실시간',
							monthly_status:'월별현황'
						},
						store_visit_age_gender:{
							tab_visiting:'방문고객',
							tab_purchasing:'구매고객',
							tab_churning:'이탈고객',
							card_title:'성별 - 연령 분포',
							card_tooltip:'상단의 버튼을 클릭하여 방문고객 - 구매고객 - 이탈고객의 성별 - 연령 분포를 볼 수 있습니다.',
							tab_visiting_heritage:'방문객',
						},
						store_visit_gender:{
							card_title:'성별 분석',
							card_tooltip:'오늘 방문 고객 성별을 백분율로 볼 수 있습니다.'
						},
						store_visit_age:{
							card_title:'연령 분석',
							card_tooltip:'오늘 방문 고객 연령을 백분율로 볼 수 있습니다.'
						},
						store_visit_race:{
							card_title:'인종 분석'
						},
						store_duration_hourly:{
							label_today:'오늘',
							label_last_week:'지난주 동요일',
							in_calculating:'집계중',
							yesterday_avg_duration:'어제 평균 체류시간',
							compare_yesterday:'전일대비',
							compare_last_week:'지난주 동요일 대비',
							card_title:'체류 시간',
							card_tooltip:'오늘 방문 고객의 체류시간을 볼 수 있습니다.'
						},
						store_duration_age_gender:{
							average:'평균',
							card_title:'성별-연령 체류시간',
							card_tooltip:'성별 - 연령에 따른 체류시간을 볼 수 있습니다.'
						},
						store_area_hourly:{
							label_today:'오늘',
							label_last_week:'지난주 동요일',
							in_calculating:'집계중',
							tab_visiting:'방문고객',
							tab_duration:'체류시간',
							card_title:'매대분석',
							card_tooltip:'방문고객 - 체류시간에 따른 매대 순위를 볼 수 있으며 해당 매대의 방문 고객 수와 체류한 시간을 알 수 있습니다.'
						},
						store_heatmap:{
							tab_visiting:'방문고객',
							tab_duration:'체류시간',
							col_ranking:'순위',
							col_shelf:'매대',
							col_category:'카테고리',
							card_title:'실시간 히트맵',
						},
						heritage:{
							site_1:'한라산',
							site_2:'만장굴',
							site_3:'대포 주상절리',
							site_4:'용머리 해안',
							site_5:'천지연 폭포',
							site_6:'거문오름',
							site_7:'성산일출봉',
							site_8:'용두암',
							site_9:'비자림',
							site_es_1:'몬주익 성',
							site_es_2:'사그라다 파밀리아',
							site_es_3:'구엘 공원',
							site_es_4:'카사 바트요',
							site_es_5:'카사 밀라',
							site_es_6:'피카소 미술관',
							site_es_7:'바르셀로나 무역센터',
							site_es_8:'카탈루냐 광장',
							site_es_9:'캄 노우',
						},
						heritage_visitor_by_location:{
							card_title:'실시간 방문객 오버뷰',
							status_very_low:'한산',
							status_low:'보통',
							status_high:'약간혼잡',
							status_very_high:'혼잡'
						},
						heritage_custom_marker:{
							status_very_low:'한산',
							status_low:'보통',
							status_high:'약간혼잡',
							status_very_high:'혼잡',
							today_visit:'오늘방문',
							today_predict:'오늘예측',
							crowded:'혼잡도',
							waiting:'대기시간'
						}
					},
					inflow:{
						admin_inflow_total_status:{
							visitor_total:'총 방문고객',
							card_title:'전체 유입 현황',
							card_tooltip:'선택한 기간 내 전체 매장 총 방문고객을 볼 수 있습니다.',
							card_tooltip_heritage:'선택한 기간 내 전체 장소 총 방문객을 볼 수 있습니다.'
						},
						admin_inflow_date:{
							avg_visitor:'평균 방문고객',
							visitor:'방문고객',
							card_title:'유입 분석',
							card_tooltip:'선택한 기간 내 전체 매장 총 방문 고객을 날짜별로 볼 수 있습니다.',
							card_tooltip_heritage:'선택한 기간 내 전체 장소 총 방문객을 날짜별로 볼 수 있습니다.',
							label_month:'월',
							label_week_number:'주차',
						},
						admin_inflow_ranking:{
							card_title:'유입 순위 요약',
							card_tooltip_compare:'선택한 기간과 이전 기간 내 전체 매장 평균 방문 고객 수를  상 - 중 - 하로 나뉘어 조회할 수 있습니다.',
							card_tooltip_compare_heritage:'선택한 기간과 이전 기간 내 전체 장소 평균 방문객 수를  상 - 중 - 하로 나뉘어 조회할 수 있습니다.',
							card_tooltip:'선택한 기간 내 전체 매장 평균 방문 고객 수를  상 - 중 - 하로 나뉘어 조회할 수 있습니다.',
							card_tooltip_heritage:'선택한 기간 내 전체 장소 평균 방문객 수를  상 - 중 - 하로 나뉘어 조회할 수 있습니다.'
						},
						admin_inflow_store_ranking:{
							avg_visitor:'평균 방문고객',
							visitor:'방문고객',
							card_title_heritage:'장소별 유입 순위',
							card_title:'매장별 유입 순위',
							card_tooltip_heritage:'선택한 기간 내 장소별 방문 고객 수의 수치를 오름차순 - 내림차순으로 볼 수 있습니다.',
							card_tooltip:'선택한 기간 내 매장별 방문 고객 수의 수치를 오름차순 - 내림차순으로 볼 수 있습니다.'
						},
						admin_inflow_weekly:{
							card_title:'요일별 유입 분석',
							card_tooltip_compare:'선택한 기간과 이전 기간 내 요일별 전체 매장의 방문 고객을 비교할 수 있습니다. 데이터는 합계와 평균 중 선택하여 조회할 수 있습니다.',
							card_tooltip_compare_heritage:'선택한 기간과 이전 기간 내 요일별 전체 장소의 방문객을 비교할 수 있습니다. 데이터는 합계와 평균 중 선택하여 조회할 수 있습니다.',
							card_tooltip:'선택한 기간 내 요일별 전체 매장의 방문 고객을 파악할 수 있습니다. 데이터는 합계와 평균 중 선택하여 조회할 수 있습니다.',
							card_tooltip_heritage:'선택한 기간 내 요일별 전체 장소의 방문객을 파악할 수 있습니다. 데이터는 합계와 평균 중 선택하여 조회할 수 있습니다.'
						},
						admin_inflow_weekly_gender_age:{
							card_title:'요일별 성별 - 연령 분석',
							card_tooltip_compare:'선택한 기간과 이전 기간을 비교하여 성별 - 연령별 방문 고객 수에 대한 요일별 합계를 백분율로 환산하여 보여줍니다.',
							card_tooltip:'선택한 기간 내 성별 - 연령별 방문 고객 수에 대한 요일별 합계를 백분율로 환산하여 보여줍니다.',
						},
						admin_customer_gender_age:{
							card_title:'성별 - 연령 분포',
							card_tooltip_heritage:'선택한 기간 내 전체 장소의 성별 - 연령 합계를 백분율로 환산하여 볼 수 있습니다.',
							card_tooltip:'선택한 기간 내 전체 매장의 성별 - 연령 합계를 백분율로 환산하여 볼 수 있습니다.'
						},
						admin_customer_gender:{
							card_title:'전체 성별 분석',
							card_tooltip_heritage:'선택한 기간 내 전체 장소의 성별 합계를 백분율로 환산하여 볼 수 있습니다.',
							card_tooltip:'선택한 기간 내 전체 매장의 성별 합계를 백분율로 환산하여 볼 수 있습니다.'
						},
						admin_customer_age:{
							card_title:'전체 연령 분석',
							card_tooltip_heritage:'선택한 기간 내 전체 장소의 연령 합계를 백분율로 환산하여 볼 수 있습니다.',
							card_tooltip:'선택한 기간 내 전체 매장의 연령 합계를 백분율로 환산하여 볼 수 있습니다.'
						},
						store:{
							header_date:'날짜',
							header_day:'요일',
							header_hour:'시간',
							header_gender:'성별',
							header_age:'연령',
							header_person_type:'유형',
							header_count:'방문수',
							label_export:'엑셀 다운로드'
						},
						store_inflow_chart:{
							label_inflow_rate:'유입률',
							label_foot_traffic:'유동인구',
							label_visiting:'방문고객',
							card_title:'유입 분석',
							card_tooltip_compare:'선택한 기간과 이전 기간의 방문 고객에 대해 일별 비교할 수 있습니다.',
							card_tooltip:'선택한 기간 내 일별 유동인구 합계, 방문고객 합계, 유입률에 대해 파악할 수 있습니다.',
						},
						store_inflow_hourly_chart:{
							label_inflow_rate:'유입률',
							label_foot_traffic:'유동인구',
							label_visiting:'방문고객',
							card_title:'시간별 유입 분석',
							card_tooltip_compare:'선택한 기간과 이전 기간의 방문 고객에 대해 시간별 비교할 수 있습니다.',
							card_tooltip:'선택한 기간의 시간별 유동인구, 방문고객, 유입률에 대해 파악할 수 있습니다. 데이터는 합계와 평균 중 선택하여 조회할 수 있습니다.'
						},
						store_visitor_gender_age_chart:{
							card_title:'방문 고객 성별 - 연령별 분석',
							card_tooltip:'선택한 기간 내 방문 고객의 성별, 연령별 오름차순으로 보여줍니다. 데이터는 요일을 선택하여 조회할 수 있습니다.',
							label_choose_day:'요일 선택'
						},
						store_visitor_gender_chart:{
							card_title:'방문 고객 성별 분포'
						},
						store_visitor_age_chart:{
							card_title:'방문 고객 연령별 분포',
							card_tooltip:'선택한 기간 내 방문 고객의 성별, 연령별 오름차순으로 보여줍니다. 데이터는 요일을 선택하여 조회할 수 있습니다.'
						},
						store_visitor_total_age_gender_chart:{
							card_title:'성별 - 연령 분포'
						},
						store_visitor_weekly_chart:{
							card_title:'요일별 성별 - 연령 분석',
							card_tooltip:'선택한 기간 내 성별 - 연령별 방문 고객 수에 대한 요일별 합계를 백분율로 환산하여 보여줍니다.'
						},
						store_visitor_date_type_chart:{
							card_title:'성별 - 연령 분석',
							card_tooltip:'선택한 기간 내 성별 - 연령별 방문 고객 수에 대한 합계를 보여줍니다.'
						},
						store_visitor_holiday_chart:{
							label_weekend:'주말',
							label_holiday:'공휴일',
							label_foot_traffic:'유동인구',
							label_visiting:'방문고객',
							card_title:'휴일에 따른 유입 분석',
						},
						store_visitor_weather_chart:{
							label_foot_traffic:'유동인구',
							label_visiting:'방문고객',
							card_title:'날씨에 따른 유입 분석',
							card_tooltip:'최근 1달동안 어제와 비슷한 날씨를 분석하여 유동인구와 방문고객 변화를 파악할 수 있습니다.',
						},
						store_inflow_weekly_chart:{
							label_direct:'매장으로 바로 유입',
							card_title:'요일별 옥외광고 분석',
							card_tooltip:'각 광고를 통해 매장에 들어온 고객을 분석하여 광고별 효과를 요일별로 파악할 수 있습니다.'
						},
						store_visitor_race_chart:{
							card_title:'방문 고객 인종 분포'
						},
						store_visitor_race_weekly_chart:{
							card_title:'요일별 인종 분석'
						},
						store_visitor_race_date_type_chart:{
							card_title:'일별 인종 분석'
						},
						admin_inflow_total_status_compare:{
							label_total_visitor:'총 방문고객',
							card_title:'전체 유입 현황',
							card_tooltip:'선택한 기간과 이전 기간 내 전체 매장 총 방문 고객을 비교할 수 있습니다.',
							label_vs_compare_period:'이전 기간 대비'
						},
						admin_inflow_date_compare:{
							card_title:'유입 분석',
							card_tooltip:'선택한 기간과 이전 기간 내 전체 매장  총 방문 고객을 일별로 비교할 수 있습니다'
						},
						admin_inflow_store_ranking_compare:{
							card_title:'매장별 유입 순위',
							card_tooltip:'선택한 기간과 이전 기간 내 매장별 방문 고객 순위를 오름차순 - 내림차순으로 볼 수 있습니다.',
						},
						admin_inflow_age_gender_compare:{
							card_title:'총 성별 - 연령별 분포',
							card_tooltip:'선택한 기간과 이전 기간 내 전체 매장의 방문 고객의 성별 - 연령을 비교할 수 있습니다. ',
						},
						store_inflow_weekly_chart_compare:{
							card_title:'요일별 옥외광고 분석',
							card_tooltip:'선택한 기간과 이전 기간의 옥외광고에 따른 유입률에 대해 비교할 수 있습니다.'
						},
						store_visitor_age_gender_day_of_week_compare:{
							card_title:'요일별 성별 - 연령 분석',
							card_tooltip:'선택한 기간과 이전 기간의 방문 고객애 대해 성별 - 연령 방문 고객 수 요일별 비교할 수 있습니다.'
						},
						store_visitor_age_gender_compare:{
							card_title:'성별 - 연령별 분포',
							card_tooltip:'선택한 기간과 이전 기간의 방문 고객애 대해 성별 - 연령 방문 고객 수를 일별 비교할 수 있습니다.'
						},
						store_visitor_gender_compare:{
							card_title:'성별 분석',
							card_tooltip:'선택한 기간과 이전 기간의 성별을 비교할 수 있습니다.'
						},
						store_visitor_age_compare:{
							card_title:'연령별 비교분석',
							card_tooltip:'선택한 기간과 이전 기간의 연령을 비교할 수 있습니다.'
						},
						store_visitor_date_type_chart_compare:{
							card_title:'성별 - 연령 분석',
							card_tooltip:'선택한 기간과 이전기간의 성별 - 연령별 방문 고객 수를 비교할 수 있습니다.'
						},
						store_visitor_race_compare:{
							card_title:'전체 인종 분석'
						},
						store_visitor_race_day_of_week_compare:{
							card_title:'요일별 인종 분석'
						},
						store_visitor_race_date_type_chart_compare:{
							card_title:'인종 분석'
						}
					},
					distribution:{
						admin_customer_gender_age_multi:{
							tab_purchasing:'구매고객',
							tab_churning:'이탈고객',
							card_title_heritage:'장소별 성별 - 연령 분포',
							card_title:'매장별 성별 - 연령 분포',
							card_tooltip_heritage:'선택한 장소의 고객 성별 - 연령 순위를 오름차순 - 내림차순으로 볼 수 있습니다.',
							card_tooltip:'선택한 매장의 고객 성별 - 연령 순위를 오름차순 - 내림차순으로 볼 수 있습니다.'
						},
						admin_customer_gender_age_select:{
							card_title_heritage:'장소 상세 성별 - 연령 분포',
							card_title:'매장 상세 성별 - 연령 분포',
							rank_first:'1위',
							rank_second:'2위',
							rank_third:'3위'
						},
						admin_customer_status_compare:{
							label_purchasing:'구매고객',
							label_churning:'이탈고객',
							card_title:'전체 고객 분포 현황',
							card_tooltip:'선택한 기간과 이전 기간 내 전체 매장 총 구매고객과 이탈고객의 비율을 비교할 수 있습니다.',
						},
						admin_customer_total_status_compare:{
							label_purchasing:'구매고객',
							label_churning:'이탈고객',
							card_title:'전체 고객 분포',
							card_tooltip:'선택한 기간과 이전 기간 내 총 방문 고객을 날짜별로 비교할 수 있습니다.'
						},
						admin_customer_ranking:{
							label_purchasing:'구매고객',
							label_churning:'이탈고객',
							card_title:'고객 분포 순위 요약',
							card_tooltip:'선택한 기간 내 전체 매장 평균 구매고객과 이탈고객을  상 - 중 - 하로 나뉘어 조회할 수 있습니다.'
						},
						admin_customer_total_ranking_compare:{
							label_purchasing:'구매고객',
							label_churning:'이탈고객',
							card_title:'매장별 고객 유형별 순위',
							card_tooltip:'선택한 기간과 이전 기간 내 매장별 구매고객과 이탈고객의 순위를 오름차순 - 내림차순으로 비교할 수 있습니다.'
						},
						admin_visitor_age_gender_compare:{
							label_purchasing:'구매고객',
							label_churning:'이탈고객',
							card_title:'성별 - 연령별 분포',
							card_tooltip:'선택한 기간과 이전 기간 내 전체 매장의 성별 - 연령 합계를 백분율로 환산하여 비교할 수 있습니다.'
						},
						admin_customer_duration_compare:{
							label_duration:'체류시간',
							label_action_person:'체험존 이용 고객',
							label_action_area:'체험존',
							card_title:'체류 시간',
							card_tooltip:'선택한 기간과 이전 기간 내 전체 매장 총 체류시간을 비교할 수 있습니다. 데이터는 합계와 평균 중 선택하여 조회할 수 있습니다.'
						},
						admin_customer_duration_ranking_compare:{
							card_title:'매장별 체류시간 순위',
							card_tooltip:'선택한 기간과 이전 기간 내 각 매장의 체류시간을 오름차순 - 내림차순으로 비교할 수 있습니다.',
						},
						admin_customer_action_ranking:{
							label_action_duration:'체험존 이용시간',
							label_action_count:'체험존 이용고객',
							card_title:'매장별 체험존 이용 순위',
							card_tooltip:'선택한 기간과 이전 기간 내 각 매장의 체험존 이용률의 합계를 백분율로 환산하여 오름차순 - 내림차순으로 비교할 수 있습니다.'
						},
						admin_customer_status:{
							label_purchasing:'구매고객',
							label_churning:'이탈고객',
							card_title:'전체 고객 분포 현황',
							card_tooltip:'선택한 기간 내 전체 매장 총 구매고객과 이탈고객의 비율을 볼 수 있습니다.'
						},
						admin_customer_total_status:{
							label_purchasing:'구매고객',
							label_churning:'이탈고객',
							card_title:'전체 고객 분포',
							card_tooltip:'선택한 기간 내 총 방문 고객을 날짜별로 볼 수 있습니다.'
						},
						admin_customer_total_ranking:{
							label_purchasing:'구매고객',
							label_churning:'이탈고객',
							label_store_avg:'전체 매장 평균 ',
							card_title:'매장별 고객 유형별 순위',
							card_tooltip:'선택한 기간 내 매장별 구매고객과 이탈고객의 순위를 오름차순 - 내림차순으로 볼 수 있습니다.',
						},
						admin_customer_gender_age:{
							card_title:'전체 성별 - 연령 분포',
							card_tooltip:'선택한 기간 내 전체 매장의 성별 - 연령 합계를 백분율로 환산하여 비교할 수 있습니다.'
						},
						admin_customer_gender:{
							card_title:'전체 성별 분석',
							card_tooltip:'선택한 기간 내 전체 매장의 성별의 합계를 백분율로 환산하여 비교할 수 있습니다.'
						},
						admin_customer_age:{
							card_title:'전체 연령 분석',
							card_tooltip:'선택한 기간 내 전체 매장의 연령의 합계를 백분율로 환산하여 비교할 수 있습니다.'
						},
						admin_customer_duration:{
							label_duration:'체류시간',
							label_action_area:'체험존',
							label_action_usage:'체험존 이용고객',
							card_title:'체류 시간',
							card_tooltip:'선택한 기간 내 전체 매장 총 체류시간을 볼 수 있습니다. 데이터는 합계와 평균 중 선택하여 조회할 수 있습니다.'
						},
						admin_customer_duration_ranking:{
							label_last_avg_duration_pre:'지난 ',
							label_last_avg_duration_post:'일 평균 체류 시간',
							label_duration:'체류시간',
							card_title:'매장별 체류시간 순위',
							card_tooltip:'선택한 기간 내 각 매장의 체류시간을 오름차순 - 내림차순으로 볼 수 있습니다.'
						},
						store_funnel:{
							card_title:'고객 퍼널 분석',
							card_tooltip:'외부 유동인구부터 구매고객까지의 전체적인 흐름을 한눈에 파악할 수 있습니다.',
							card_tooltip_compare:'선택한 기간과 이전 기간의 전체적인 흐름을 한눈에 비교할 수 있습니다.',
							label_foot_traffic:'유동인구',
							label_visiting:'방문고객',
							label_purchasing:'구매고객',
						},
						store_funnel_card:{
							label_all:'전체',
							label_compare_period:'비교기간'
						},
						store_arrow_data:{
							label_compare_period:'비교기간'
						},
						store_trend_chart:{
							label_purchasing_ratio:'구매율',
							label_churning_ratio:'이탈률',
							label_purchasing:'구매고객',
							label_churning:'이탈고객',
							card_title:'고객 추이 분석',
							card_tooltip:'방문 고객 중 구매 또는 이탈로 이어진 고객의 수와 전환율을 조회할 수 있습니다.'
						},
						store_time_chart:{
							label_visit_rate:'방문율',
							label_conversion_rate:'구매전환율',
							label_churning_rate:'이탈률',
							card_title:'시간대별 방문율 - 구매전환율 - 이탈률 분석',
							card_tooltip:'선택한 기간에 대해 각각의 시간대별 통계를 조회할 수 있습니다.'
						},
						store_age_gender_chart:{
							card_title_purchasing:'구매 고객 성별 - 연령 분석',
							card_title_churning:'이탈 고객 성별 - 연령 분석',
							card_tooltip:'선택한 기간의 성별 - 연령별에 대한 방문 고객 수를 요일별 합계를 백분율로 환산하여 비교할 수 있습니다.'
						},
						store_residence:{
							card_title:'체류시간별 행동전환 고객 분석',
							card_tooltip:'설정한 기간 내, 지정 체류시간에 해당하는 고객들 중 행동전환 고객, 그 중 구매까지 이어진 고객의 분포를 합계로 파악할 수 있습니다.',
							col_duration_dependency_count:'체류시간에 따른 고객 수',
							col_action_conversion:'행동전환',
							label_all:'전체',
							col_tooltip:'행동전환이란, 고객 경험 가치를 향상시킬 수 있는 공간을 뜻하며, 사전에 설정된 특정 구역을 포함하고 있습니다.',
							col_tooltip_compare:'선택한 기간과 이전 기간의 지정 체류시간에 해당하는 고객들 중 행동전환 고객, 그 중 구매까지 이어진 고객의 분포를 비교할 수 있습니다.',
							col_purchasing_conversion:'구매전환',
							label_compare_period:'비교 기간'
						},
						store_trend_chart_compare:{
							label_purchasing:'구매고객',
							label_churning:'이탈고객',
							label_purchasing_rate:'구매율',
							label_churning_rate:'이탈률',
							card_title:'고객 추이 분석',
							card_tooltip:'선택한 기간과 이전 기간의 구매고객, 이탈고객을 비교할 수 있습니다.'
						},
						store_time_chart_compare:{
							label_visiting_rate:'방문율',
							label_conversion_rate:'구매전환율',
							label_churning_rate:'이탈률',
							card_title:'시간대별 방문율 - 구매전환율 - 이탈률 분석',
							card_tooltip:'선택한 기간과 이전 기간의 방문율, 구매전환율, 이탈률를 비교할 수 있습니다.'
						},
						store_visitor_gender_compare:{
							card_title:'성별 분석',
							card_tooltip:'선택한 기간과 이전 기간의 성별에 대한 방문 고객 수를 요일별 합계를 백분율로 환산하여 비교할 수 있습니다.'
						},
						store_visitor_age_compare:{
							card_title:'연령별 비교분석',
							card_tooltip:'선택한 기간과 이전 기간의 연령에 대한 방문 고객 수를 비교할 수 있습니다.'
						}
					},
					heritage:{
						col_visitor:'방문객',
						col_enter:'입장시간',
						col_leave:'퇴장시간',
						col_status:'현재상태',
						status_in_dwell:'체류중',
						status_exit:'퇴장',
						label_today_inflow:'금일유입고객',
						label_today_exit:'금일퇴장고객',
						label_in_dwell:'현재잔류고객',
						card_title:'방문객 목록'
					},
					area:{
						admin_visitor_category:{
							card_title:'카테고리별 방문 고객 순위',
							card_tooltip:'선택한 기간 내 전체 매장 방문 고객의 카테고리 순위를 오름차순 - 내림차순으로 볼 수 있습니다.',
							card_tooltip_compare:'선택한 기간과 이전 기간 내 전체 매장 방문 고객의 카테고리 순위를 오름차순 - 내림차순으로 비교할 수 있습니다.',
							label_target:'기준',
							label_compare:'비교'
						},
						admin_duration_category:{
							label_target:'기준',
							label_compare:'비교',
							card_title:'카테고리별 체류시간 순위',
							card_tooltip:'선택한 기간 내 전체 매장 체류시간의 카테고리 순위를 오름차순 - 내림차순으로 볼 수 있습니다.',
							card_tooltip_compare:'선택한 기간과 이전 기간 내 전체 매장 체류시간의 카테고리 순위를 오름차순 - 내림차순으로 비교할 수 있습니다.',
						},
						admin_age_gender_category:{
							label_target:'기준',
							label_compare:'비교',
							label_visitor:'방문고객',
							label_duration:'체류시간',
							card_title:'카테고리별 성별-연령 분포',
							card_tooltip_compare:'선택한 기간과 이전 기간 내 방문 고객 수 기준으로 카테고리 순위 5개에 대한 성별 - 연령을 오름차순 - 내림차순으로 비교할 수 있습니다.',
							card_tooltip:'선택한 기간 내 방문 고객 수 기준으로 카테고리 순위 5개에 대한 성별 - 연령을 오름차순 - 내림차순으로 볼 수 있습니다.'
						},
						store_rank_table:{
							col_category:'카테고리',
							col_cur_ranking:'현재순위',
							col_shelf_name:'매대이름',
							col_visitors:'방문 고객 수',
							col_duration:'체류시간',
							col_last_ranking:'이전순위',
							card_title_by_visitor:'방문 고객별 매대순위',
							card_title_by_duration:'체류 시간별 매대순위'
						},
						store_area_analysis_drawer:{
							card_title:'전체 매대 분석',
							tab_visitor_analysis:'방문고객 분석',
							tab_duration_analysis:'체류시간 분석',
							tab_revenue_analysis:'성과 분석'
						},
						store_visitor_period_chart:{
							label_visitor:'방문고객',
							card_title:'방문고객 분석',
							card_tooltip:'선택된 매대 방문고객 수를 조회할 수 있으며, 매출과 판매량을 같이 조회할 수 있습니다.',
							summary_date:'에 최다 고객이 매대에 방문했습니다.',
							summary_val_pre:'방문고객은 ',
							summary_val_post:'명 입니다.',
						},
						store_visitor_hourly_chart:{
							visitor_rate:'방문 고객 비율',
							label_visitor:'방문고객',
							card_title:'시간대별 매대 방문고객 수',
							card_tooltip:'시간대별 매대에 방문한 방문고객의 수와 성별 - 연령에 대한 비율을  합계 - 평균으로 조회할 수 있습니다.',
							summary_hour:'에 최다 고객이 매대에 방문했습니다.',
							summary_val_pre:'방문고객은 ',
							summary_val_post:'명 입니다.',
						},
						store_visitor_gender_age_chart:{
							label_highest:'최다 방문 고객',
							label_lowest:'최소 방문 고객',
							card_title:'방문고객 성별-연령 분포'
						},
						store_visitor_day_of_week_chart:{
							label_sum_visitor:'총 방문자 수',
							label_avg_visitor:'평균 방문자 수',
							card_title:'요일별 방문 고객 분석',
							card_tooltip:'요일별로 매대의 방문 고객 수와 성별 방문고객 수를 합계 - 평균으로 조회할 수 있습니다.',
							label_visitor:'방문고객',
							label_summary_count:'명으로',
							summary_post:'가장 많이 방문했습니다.'
						},
						store_visitor_gender_age_compare_chart:{
							card_title:'방문고객 성별-연령 분포'
						},
						store_duration_period_chart:{
							label_duration:'체류시간',
							card_title:'매대 체류시간',
							card_tooltip:'선택된 매대 방문고객의 체류시간을 조회할 수 있으며, 매출과 판매량를 같이 조회할 수 있습니다.',
							summary_date:'에 최다 시간 매대에 방문했습니다.',
							summary_val_pre:'체류시간은 ',
							summary_val_post:'분 입니다.',
						},
						store_duration_hourly_chart:{
							hour_suffix:'시',
							label_duration:'체류시간',
							card_title:'시간대별 체류시간',
							card_tooltip:'시간대별 체류시간과 성별 - 연령의 체류시간 분포를 합계 - 평균으로 조회할 수 있습니다.',
							summary_hour:'에 매대에 제일 오래 체류 했습니다.',
							summary_val_pre:'체류시간은 ',
							summary_val_post:'분 입니다.',
						},
						store_duration_gender_age_chart:{
							card_title:'성별-연령별 체류시간 분석',
							label_longest:'최장 체류시간',
							label_shortest:'최단 체류시간'
						},
						store_duration_day_of_week_chart:{
							label_sum_duration:'총 체류시간',
							label_avg_duration:'평균 체류시간',
							card_title:'요일별 체류시간 분석',
							card_tooltip:'요일별로 매대의 체류시간과 성별 체류시간을 합계 - 평균으로 조회할 수 있습니다.',
							label_duration:'고객 체류시간',
							label_summary_count:'분으로',
							summary_post:'가장 많이 체류했습니다.'
						},
						store_duration_gender_age_compare_chart:{
							card_title:'성별-연령별 체류시간 분석'
						}
					},
					flow:{
						flow_sankey:{
							label_limit_5:'5번째 까지',
							label_limit_10:'10번째 까지',
							label_limit_15:'15번째 까지',
							label_limit_20:'20번째 까지',
							card_title:'동선 분석',
							switchStand: '매대 별',
							switchCategory: '카테고리 별'
						},
						sankey_node:{
							label_next_category:'다음 이동 카테고리',
							label_moved_person:'이동 고객 수'
						}
					},
					heatmap:{
						index:{
							label_all:'전체',
							card_title:'히트맵 분석',
							card_tooltip:'선택한 기간 내 어디를 중점적으로 방문 고객이 머물렀는지 색상별로 알 수 있습니다.',
							label_time_filter:'시간 설정'
						},
						tab:{
							label_all:'전체',
							tab_count:'방문고객수',
							tab_duration:'체류시간',
							card_title_count:'매대별 방문고객 순위',
							card_title_duration:'매대별 체류시간 순위',
							col_ranking:'순위',
							col_shelf:'매대 ID',
							col_category:'카테고리',
							col_visiting:'방문고객수',
							col_duration:'체류시간'
						}
					},
					promotion:{
						index:{
							label_add:'프로모션 등록',
							label_filter:'필터',
							label_promotion:'프로모션'
						},
						admin_inflow_total:{
							label_last_year:'작년 동기간',
							card_title:'프로모션 전체 유입 현황',
							card_tooltip:'선택한 프로모션 기간 내 전체 매장 총 방문 고객을 볼 수 있습니다.',
							compare_last_year:'작년 동기 대비'
						},
						admin_inflow_date:{
							label_visiting:'방문고객',
							label_purchasing:'구매고객',
							label_conversion_rate:'구매전환율',
							card_title:'프로모션 고객 분석',
							card_tooltip:'선택한 프로모션 기간 내 전체 매장 총 방문 고객을 날짜별로 볼 수 있습니다.',
							compare_last_year_conversion_rate:'작년 동기 대비 구매전환율'
						},
						admin_inflow_ranking:{
							card_title:'프로모션 유입 순위 요약',
							card_tooltip:'선택한 프로모션 기간 내 전체 매장 유입 순위 요약을 조회 할 수 있습니다.'
						},
						admin_inflow_store_ranking:{
							label_avg_visiting:'평균 방문고객',
							label_visiting:'방문고객',
							card_title:'프로모션 매장별 유입 순위',
							card_tooltip:'선택한 기간 내 매장별 방문 고객 수의 수치를 오름차순 - 내림차순으로 볼 수 있습니다.'
						},
						admin_inflow_gender_age:{
							card_title:'프로모션 성별 - 연령 분석'
						},
						admin_inflow_gender:{
							card_title:'전체 성별 분석',
							card_tooltip:'선택한 프로모션 기간 내 방문 고객 성별 합계를 백분율로 환산하여 보여줍니다.'
						},
						admin_inflow_age:{
							card_title:'프로모션 연령 분석',
							card_tooltip:'선택한 프로모션 기간 내 방문 고객 연령 합계를 백분율로 환산하여 보여줍니다.'
						},
						admin_inflow_gender_age_multi:{
							card_title:'매장별 성별 - 연령 분포',
							card_tooltip:'선택한 프로모션 기간 내 매장별 방문 고객 성별 - 연령 순위를 오름차순 - 내림차순으로 볼 수 있습니다.'
						},
						admin_inflow_gender_age_select:{
							card_title:'매장 상세 성별 - 연령 분포',
							card_tooltip:'선택한 프로모션 기간 내 매장의 성별 - 연령을 백분율로 환산하여 보여줍니다.'
						},
						admin_inflow_visitor_category:{
							card_title:'카테고리별 방문 고객 순위',
							card_tooltip:'선택한 프로모션 기간 내 기간 방문 고객이 많이 방문한 카테고리를 오름차순 - 내림차순으로 볼 수 있습니다.'
						},
						admin_inflow_duration_category:{
							card_title:'카테고리별 체류시간 순위',
							card_tooltip:'선택한 프로모션 기간 내 방문 고객이 머무른 카테고리를 오름차순 - 내림차순으로 볼 수 있습니다.'
						},
						store_inflow_date:{
							label_foot_traffic:'유동인구',
							label_visiting:'방문고객',
							label_purchasing:'구매고객',
							label_inflow_rate:'유입률',
							label_conversion_rate:'구매전환율',
							card_title:'프로모션 고객 분석',
							card_tooltip:'선택한 프로모션 기간 내 매장 총 방문 고객을 날짜별로 볼 수 있습니다.'
						},
						store_inflow_hourly_chart:{
							hour_suffix:'시',
							label_foot_traffic:'유동인구',
							label_visiting:'방문고객',
							label_purchasing:'구매고객',
							label_inflow_rate:'유입률',
							label_conversion_rate:'구매전환율',
							card_title:'프로모션 시간별 고객 분석',
							card_tooltip:'선택한 프로모션 기간 내 매장 총 방문 고객을 시간별로 볼 수 있습니다.'
						},
						store_inflow_weekly_chart:{
							card_title:'프로모션 요일별 고객 분석',
							card_tooltip:'선택한 프로모션 기간 내 요일별 매장의 방문 고객을 파악할 수 있습니다.'
						},
						store_area_analysis_content:{
							col_category:'카테고리',
							col_shelf:'매대이름',
							col_visiting:'방문고객수(명)',
							col_duration:'체류시간(분)',
							card_title:'매대 분석',
							card_tooltip:'선택한 프로모션 기간 내 매대 순위을 볼 수 있으며 해당 매대의 방문 고객 수와 체류한 시간을 알 수 있습니다.'
						},
						store_visitor_date_type_chart:{
							card_title:'프로모션 성별 - 연령 타겟 분석'
						},
						store_visitor_gender_age_index:{
							card_title:'프로모션 성별 - 연령 분석'
						},
						store_visitor_gender_chart:{
							card_title:'프로모션 성별 분포'
						},
						store_visitor_gender_age_chart:{
							card_title:'성별 - 연령 분포'
						},
						store_visitor_weekly_chart:{
							card_title:'프로모션 요일별 성별 - 연령 분석'
						},
						manage_search_text_bar:{
							placeholder:'프로모션명을 검색해 보세요.'
						},
						manage_search_card:{
							label_in_progress:'진행중',
							label_done:'완료',
							label_latest:'최신순',
							label_oldest:'오래된순',
							label_high_revenue:'매출 높은순',
							label_low_revenue:'매출 낮은순'
						},
						manage_search_filter:{
							label_period:'프로모션 기간',
							label_status:'프로모션 상태'
						},
						manage_promotion_create:{
							label_add:'프로모션 추가'
						},
						manage_promotion_list:{
							label_type:'종류',
							label_period:'기간',
							label_time:'시간',
							label_day_of_week:'요일',
							label_real_revenue:'실제 매출',
							label_target_revenue:'목표 매출',
							label_visitors:'방문고객',
							label_gender_age:'핵심 타겟',
							label_gender:'성별',
							label_age:'연령',
							label_currency_suffix:'(원)'
						},
						register_index:{
							err_name:'프로모션 명을 입력해주세요.',
							err_type:'종류를 입력해주세요.',
							err_period:'기간을 입력해주세요.',
							err_store:'매장을 선택해주세요.',
							err_time:'등록 할 수 없는 시간 범위입니다.',
							req_time:'상세 시간별에 빈칸을 채워주세요',
							err_day:'상세 요일을 선택해주세요',
							register_success:'저장되었습니다.',
							register_fail:'저장을 실패했습니다.',
							modify_success:'수정되었습니다.',
							modify_fail:'수정에 실패했습니다.'
						},
						promotion_name:{
							label_promotion_name:'프로모션 명',
							label_mandatory:'필수항목',
							label_type:'종류'
						},
						promotion_multi_store:{
							label_store:'매장',
							label_mandatory:'필수항목',
							label_available:'선택 전',
							label_selected:'선택 후'
						},
						promotion_range:{
							label_promotion_period:'프로모션 기간'
						},
						promotion_date:{
							label_period:'기간'
						},
						promotion_detail_setting:{
							label_detail_setting:'상세설정',
							label_on:'설정',
							label_off:'설정안함',
							label_detail:'상세',
							err_check:'체크박스를 확인해주세요.',
							label_time_range:'시간별',
							label_day_of_week_range:'요일별'
						},
						promotion_target_times:{
							err_time:'등록 할 수 없는 시간 범위입니다.',
							label_time_range:'시간별 (24시간제)',
							label_start:'시작',
							label_end:'종료',
							label_hour_suffix:'시'
						},
						promotion_target_days:{
							label_day_of_week:'요일별'
						},
						promotion_target_show:{
							label_promotion_time:'프로모션 시간',
							label_promotion_day_of_week:'프로모션 요일'
						},
						promotion_target_sales:{
							label_target_sales_setting:'목표매출 설정',
							label_pos:'(POS 연동)',
							label_target_sales:'목표 매출',
							label_currency_suffix:'원'
						},
						promotion_target_customer:{
							label_promotion_target:'프로모션 타겟',
							label_visiting:'방문고객',
							label_gender:'성별',
							label_age:'연령',
							label_gender_age:'핵심 타겟'
						},
						promotion_button:{
							label_save:'저장하기',
							label_modify:'수정하기',
							label_cancel:'취소'
						}
					},
					report: {
						excel:'엑셀',
						pdf:'PDF',
						modalTitle: '{{modalTitle, customReportModalTitle}}',
						report_header:'주/월간 리포트',
						report_desc: '* 매월 1일 전월에 대한 보고서가 생성됩니다.',
						no_data:'다운로드 가능한 리포트가 없습니다.',
						visitors: '방문자수',
						desc: '매대분석',
						funnel: '퍼널분석',
						month_1: '1월',
						month_2: '2월',
						month_3: '3월',
						month_4: '4월',
						month_5: '5월',
						month_6: '6월',
						month_7: '7월',
						month_8: '8월',
						month_9: '9월',
						month_10: '10월',
						month_11: '11월',
						month_12: '12월',
						title: '최근 리포트',
						file_name: '파일명',
						download: '다운로드',
						data_set: '데이터 집계중입니다.',
						set_desc: '파일이 생성되는 동안 잠시만 기다려 주세요.',
						monthlyReport: "월간 리포트",
						weeklyDataRangeHeader: "데이터 기간 (월요일 ~ 일요일)",
						compareDataRangeHeader: "비교 기간 (월요일 ~ 일요일)",
						weeklyHelper: "* 매주 월요일에 직전 7일(월~일)에 대한 리포트가 생성됩니다.",
						weeklyCompareLabel:"비교 기간",
						weeklyCompareBefore:"지난주",
						weeklyCompareBeforeMonth:"전월 동주",
						weeklyCompareBeforeYear:"전년 동월 동주",
						monthDataTableFilename:"{{year}}년 {{month}}월 월간 리포트",
						weeklyDataTableFilname:"{{year}}년 {{month}}월 {{weekNum}}주차 주간 리포트"
					}
				}
			},
			en:{
				translation:{
					common:{
						year: "year",
						month: "month",
						select_default:"Select",
						label_unknown:'Unknown',
						label_male:'Male',
						label_female:'Female',
						label_age_0:'~10s',
						label_age_10:'10s',
						label_age_20:'20s',
						label_age_30:'30s',
						label_age_40:'40s',
						label_age_50:'50s',
						label_age_60:'60s~',
						person_count_suffix:'',
						label_asc:'Ascending',
						label_desc:'Descending',
						label_unit:'Unit',
						minute_suffix:'min',
						current_time:'Current',
						last_update_time:'Last Updated',
						label_prev:'Prev',
						label_next:'Next',
						period_analysis:'Period',
						compare_analysis:'Compare',
						period_daily:'Daily',
						period_weekly:'Weekly',
						period_monthly:'Montly',
						label_sum:'Sum',
						label_avg:'Average',
						label_mon:'Mon',
						label_tue:'Tue',
						label_wed:'Wed',
						label_thu:'Thu',
						label_fri:'Fri',
						label_sat:'Sat',
						label_sun:'Sun',
						label_full_mon:'Mon',
						label_full_tue:'Tue',
						label_full_wed:'Wed',
						label_full_thu:'Thu',
						label_full_fri:'Fri',
						label_full_sat:'Sat',
						label_full_sun:'Sun',
						logout:'Logout',
						월:'Mon',
						화:'Tue',
						수:'Wed',
						목:'Thu',
						금:'Fri',
						토:'Sat',
						일:'Sun',
						월_full:'Monday',
						화_full:'Tuesday',
						수_full:'Wednesday',
						목_full:'Thursday',
						금_full:'Friday',
						토_full:'Saturday',
						일_full:'Sunday',
						월요일:'Monday',
						화요일:'Tuesday',
						수요일:'Wednesday',
						목요일:'Thursday',
						금요일:'Friday',
						토요일:'Saturday',
						일요일:'Sunday',
						label_white:'White',
						label_black:'African',
						label_latino_hispanic:'Latino & Hispanic',
						label_east_asian:'East Asian',
						label_southeast_asian:'Southeast Asian',
						label_indian:'Indian',
						label_middle_eastern:'Middle Eastern',
						label_sum:'Sum',
						label_avg:'Avg',
						all:'All'
					},
					navigation:{
						all:'All',
						dashboard:'Dashboard',
						customer_header:"Inflow Customer Analysis",
						customer_header_heritage:'Inflow Visitor Analysis',
						store_inflow:'Store Inflow Analysis',
						store_inflow_heritage:'Location Inflow Analysis',
						customer_dist:'Customer Distribution',
						heritage_visitors:'Visitor Management',
						promotion_header:'Event Analysis',
						promotion_analysis:'Promotion Analysis',
						settings_header:'Settings',
						general_setting:'General Settings',
						area_setting:'Area Settings',
						promotion_manager:'Promotion Settings',
						reports_header:'report',
						reports_title:'',
						reports_print:'report',
						reports_print_week:'Week Report',
						reports_print_month:'Month Report',
						store_analysis_header:'Store Analysis',
						counter_analysis:'Shelf Analysis',
						flow_analysis:'Flow Analysis',
						heatmap:'Heatmap Analysis',
						promotion_register:'Promotion Register',
						promotion_detail:'Promotion Detail'
					},
					search_filter:{
						label_period:'Period',
						yesterday:'Yesterday',
						last_week:'Last 7days',
						last_month:'Last 30days',
						last_two_month:'Last 60days',
						last_quarter:'Last 90days',
						label_custom_period:'Custom Period',
						all:'All',
						label_custom:'Custom',
						label_filter:'Filter',
						label_gender:'Gender',
						label_age:'Age',
						label_compare_period:'Compare Period',
					},
					dashboard:{
						summary:{
							badge_visiting:'Visiting',
							badge_purchasing:'Purchasing',
							badge_target:'Target',
							badge_duration:'Dwell Time',
							badge_purchase:'Purchase',
							badge_category:'Category',
						},
						admin_visit_daily:{
							card_title:'Overall Inflow Status',
							card_tooltip:'You can view today\'s visiting customers and the trend of visitors over the past 7 days.',
							compare_yesterday:'vs Yesterday',
							compare_last_week:'vs LastWeek',
							label_visit_person:'Visit Person',
							label_last_week_avg_visit_person:'Last Week Average Visit Person'
						},
						admin_visit_hourly:{
							card_title:'Hourly Overall Inflow Status',
							card_tooltip:'You can see the real-time total number of visiting customers across all stores today.',
							compare_yesterday:'vs Yesterday',
							compare_last_week:'vs Last Week',
							in_calculating:'In Calculating',
							label_today:'Today',
							label_last_week:'Last Week',
							label_yesterday_avg_visit_person:'Yesterday Average Visit Person'
						},
						admin_visit_by_store:{
							tab_foot_traffic:'Foot Traffic',
							tab_visiting:'Visiting',
							tab_purchasing:'Purchasing',
							tab_churning:'Churning',
							card_tooltip:'You can view the rankings of foot traffic, visiting customers, purchasing customers, and churned customers for all stores in ascending or descending order.',
							card_title_lite:'Real-time Store-specific Visiting Customer Status',
							card_title_heritage:'Real-time Visitor Status by Location',
							label_avg:'Average',
							label_visitor:'Visitor',
						},
						admin_visit_age_gender:{
							card_title:'Overall Gender-Age Distribution',
						},
						admin_visit_gender:{
							card_title:'Overall Gender Analysis',
							card_tooltip:'You can view the gender distribution of visiting customers across all stores today as a percentage.',
						},
						admin_visit_age:{
							card_title:'Overall Age Analysis',
							card_tooltip:'You can view the age distribution of visiting customers across all stores today as a percentage.',
						},
						admin_visit_age_gender_by_store:{
							tab_visiting:'Visiting',
							tab_purchasing:'Purchasing',
							tab_churning:'Churning',
							card_title:'Real-time Store-specific Gender-Age Analysis',
							card_tooltip:'You can view the gender-age distribution of visiting customers for each location in ascending or descending order. Clicking on a graph allows you to see the gender-age distribution for that specific store in ascending order on the right-side graph.',
							card_title_sub:'Store-Specific Gender-Age Distribution Details',
							card_tooltip_sub:'You can view the gender-age distribution of the store in ascending or descending order.',
							card_title_lite:'Real-time Store-specific Gender-Age Analysis',
							card_title_heritage:'Location-Specific Gender-Age Analysis',
							card_title_sub_lite:'Store-Specific Gender-Age Distribution Details',
							card_title_sub_heritage:'Location-Specific Gender-Age Distribution Details',
						},
						admin_visit_gender_by_store:{
							card_title:'Store-Specific Gender Analysis',
							card_title_heritage:'Location-Specific Gender Analysis',
							card_tooltip:'You can view the gender distribution of visiting customers for each location in ascending or descending order.'
						},
						admin_visit_age_by_store:{
							card_title:'Store-Specific Age Analysis',
							card_title_heritage:'Location-Specific Age Analysis',
							card_tooltip:'You can view the age distribution of visiting customers for each location in ascending or descending order.'
						},
						admin_duration_daily:{
							last_week_avg_duration:'Average Dwell Time Last Week',
							card_title:'Overall Dwell Time',
							card_tooltip:'You can view the dwell time of customers across all stores.',
						},
						admin_duration_gender:{
							last_week_avg_duration:'Average Dwell Time Last Week',
							card_title:'Gender-Specific Dwell Time',
							card_tooltip:'You can view dwell time based on gender.',
						},
						admin_duration_age:{
							last_week_avg_duration:'Average Dwell Time Last Week',
							card_title:'Age-Specific Dwell Time',
							card_tooltip:'You can view dwell time based on age.',
						},
						admin_duration_by_store:{
							yesterday_avg_duration:'Average Dwell Time Yesterday',
							card_title:'Store-Specific Dwell Time',
							card_tooltip:'You can view the dwell time of customers for each store in ascending or descending order.',
						},
						admin_duration_gender_by_store:{
							duration:'Dwell Time',
							card_title:'Store-Specific Gender Dwell Time',
							card_tooltip:'You can view store-specific gender dwell time in ascending or descending order.',
						},
						store_visit_hourly:{
							tab_foot_traffic:'Foot Traffic',
							tab_visiting:'Visiting',
							tab_purchasing:'Purchasing',
							tab_churning:'Churning',
							yesterday_avg:'Yesterday Avg',
							label_today:'Today',
							label_last_week:'Last Week',
							month_avg:'Month Avg',
							in_calculating:'In Calculating',
							card_tooltip:'You can click on the buttons at the top to view real-time foot traffic, visiting customers, purchasing customers, and churned customers status.',
							compare_yesterday:'vs Yesterday',
							compare_last_week:'vs Last Week',
							realtime:'Realtime',
							monthly_status:'Monthly Status'
						},
						store_visit_age_gender:{
							tab_visiting:'Visiting',
							tab_purchasing:'Purchasing',
							tab_churning:'Churning',
							card_title:'Gender-Age Distribution',
							card_tooltip:'You can click on the buttons at the top to view the gender-age distribution of visiting customers, purchasing customers, and churned customers.',
							tab_visiting_heritage:'Visiting',
						},
						store_visit_gender:{
							card_title:'Gender Analysis',
							card_tooltip:'You can view the gender distribution of visiting customers today as a percentage.'
						},
						store_visit_age:{
							card_title:'Age Analysis',
							card_tooltip:'You can view the age distribution of visiting customers today as a percentage.'
						},
						store_visit_race:{
							card_title:'Race Analysis'
						},
						store_duration_hourly:{
							label_today:'Today',
							label_last_week:'Last Week',
							in_calculating:'In Calculating',
							yesterday_avg_duration:'Average Dwell Time Yesterday',
							compare_yesterday:'vs Yesterday',
							compare_last_week:'vs Last Week',
							card_title:'Dwell Time',
							card_tooltip:'You can view the dwell time of visiting customers today.'
						},
						store_duration_age_gender:{
							average:'Average',
							card_title:'Gender-Age Dwell Time',
							card_tooltip:'You can view dwell time based on gender and age.'
						},
						store_area_hourly:{
							label_today:'Today',
							label_last_week:'Last Week',
							in_calculating:'In Calculating',
							tab_visiting:'Visiting',
							tab_duration:'Dwell Time',
							card_title:'Shelf Analysis',
							card_tooltip:'You can view the shelf ranking based on visiting customer dwell time, and see the number of visiting customers and their dwell time for each shelf.'
						},
						store_heatmap:{
							tab_visiting:'Visiting',
							tab_duration:'Dwell Time',
							col_ranking:'Ranking',
							col_shelf:'Shelf',
							col_category:'Category',
							card_title:'Realtime Heatmap',
						},
						heritage:{
							site_1:'Hallasan',
							site_2:'Manjanggul Lava Tubes',
							site_3:'Daepo Jusangjeolli Cliff',
							site_4:'Yongmeori Beach',
							site_5:'Cheonjiyeon Waterfall',
							site_6:'Geomunoreum',
							site_7:'Seongsan Ilchulbong Sunrise Park',
							site_8:'Yongduam Rock',
							site_9:'Bijarim Forest',
							site_es_1:'Castell de Montjuïc',
							site_es_2:'La Sagrada Família',
							site_es_3:'Parc Güell',
							site_es_4:'Casa Batlló',
							site_es_5:'Casa Milà',
							site_es_6:'Museu Picasso de Barcelona',
							site_es_7:'Fira Barcelona Gran Via',
							site_es_8:'Plaça de Catalunya',
							site_es_9:'Camp Nou',
						},
						heritage_visitor_by_location:{
							card_title:'Realtime Visitor Overview',
							status_very_low:'Very Low',
							status_low:'Low',
							status_high:'High',
							status_very_high:'Very High'
						},
						heritage_custom_marker:{
							status_very_low:'Very Low',
							status_low:'Low',
							status_high:'High',
							status_very_high:'Very High',
							today_visit:'Today Visit',
							today_predict:'Tdoay Predict',
							crowded:'Crowded',
							waiting:'Waiting'
						}
					},
					inflow:{
						admin_inflow_total_status:{
							visitor_total:'Total Visitor',
							card_title:'Overall Inflow Status',
							card_tooltip:"You can see the total visiting customers across all stores for the selected period.",
							card_tooltip_heritage:"You can see the total visiting customers across all locations for the selected period."
						},
						admin_inflow_date:{
							avg_visitor:'Average Visitor',
							visitor:'Visitor',
							card_title:'Inflow Analysis',
							card_tooltip:"You can see the total visiting customers across all stores on a daily basis for the selected period.",
							card_tooltip_heritage:"You can see the total visiting customers across all locations on a daily basis for the selected period.",
							label_month:'',
							label_week_number:'',
						},
						admin_inflow_ranking:{
							card_title:'Inflow Ranking Summary',
							card_tooltip_compare:"You can compare the average number of visiting customers across all stores for the selected and previous periods, categorized as high, medium, and low.",
							card_tooltip_compare_heritage:"You can compare the average number of visiting customers across all locations for the selected and previous periods, categorized as high, medium, and low.",
							card_tooltip:"You can categorize the average number of visiting customers across all stores for the selected period as high, medium, and low.",
							card_tooltip_heritage:"You can categorize the average number of visiting customers across all locations for the selected period as high, medium, and low."
						},
						admin_inflow_store_ranking:{
							avg_visitor:'Average Visitor',
							visitor:'Visitor',
							card_title_heritage:'Location-Specific Inflow Ranking',
							card_title:'Store-Specific Inflow Ranking',
							card_tooltip_heritage:"You can view the numerical values of visiting customers per location for the selected period in ascending or descending order.",
							card_tooltip:"You can view the numerical values of visiting customers per store for the selected period in ascending or descending order."
						},
						admin_inflow_weekly:{
							card_title:"Weekday Inflow Analysis",
							card_tooltip_compare:"You can compare the visiting customers for each weekday across all stores for the selected and previous periods. You can choose to view the data as a sum or an average.",
							card_tooltip_compare_heritage:"You can compare the visiting customers for each weekday across all locations for the selected and previous periods. You can choose to view the data as a sum or an average.",
							card_tooltip:"You can understand the visiting customers for each weekday across all stores for the selected period. You can choose to view the data as a sum or an average.",
							card_tooltip_heritage:"You can understand the visiting customers for each weekday across all locations for the selected period. You can choose to view the data as a sum or an average.",
						},
						admin_inflow_weekly_gender_age:{
							card_title:'Weekday Gender-Age Analysis',
							card_tooltip_compare:"It compares the sum of gender-age-specific visitors on each weekday, converted into a percentage, for the selected and previous periods.",
							card_tooltip:"It shows the percentage of the sum of gender-age-specific visitors on each weekday for the selected period.",
						},
						admin_customer_gender_age:{
							card_title:'Gender - Age Distribution',
							card_tooltip_heritage:"You can view the percentage distribution of the total gender-age sum across all locations for the selected period.",
							card_tooltip:"You can view the percentage distribution of the total gender-age sum across all stores for the selected period."
						},
						admin_customer_gender:{
							card_title:'Overall Gender Analysis',
							card_tooltip_heritage:"You can view the percentage distribution of the total gender sum across all locations for the selected period.",
							card_tooltip:"You can view the percentage distribution of the total gender sum across all stores for the selected period."
						},
						admin_customer_age:{
							card_title:'Overall Age Analysis',
							card_tooltip_heritage:"You can view the percentage distribution of the total age sum across all locations for the selected period.",
							card_tooltip:"You can view the percentage distribution of the total age sum across all stores for the selected period.",
						},
						store:{
							header_date:'Date',
							header_day:'Day of Week',
							header_hour:'Hour',
							header_gender:'Gender',
							header_age:'Age',
							header_person_type:'Type',
							header_count:'Count',
							label_export:'Export'
						},
						store_inflow_chart:{
							label_inflow_rate:'Inflow Rate',
							label_foot_traffic:'Foot Traffic',
							label_visiting:'Visiting',
							card_title:'Inflow Analysis',
							card_tooltip_compare:"You can compare the daily visiting customers for the selected and previous periods.",
							card_tooltip:"You can understand the daily total foot traffic, visiting customers, and inflow rate for the selected period."
						},
						store_inflow_hourly_chart:{
							label_inflow_rate:'Inflow Rate',
							label_foot_traffic:'Foot Traffic',
							label_visiting:'Visiting',
							card_title:'Time Specific Inflow Analysis',
							card_tooltip_compare:"You can compare the hourly visiting customers for the selected and previous periods.",
							card_tooltip:"You can understand the hourly foot traffic, visiting customers, and inflow rate for the selected period. You can choose to view the data as a sum or an average."
						},
						store_visitor_gender_age_chart:{
							card_title:"Visiting Customer Gender-Age Analysis",
							card_tooltip:"It shows the gender and age distribution of visiting customers for the selected period in ascending order. You can choose a day of the week to view the data.",
							label_choose_day:'Day of Week'
						},
						store_visitor_gender_chart:{
							card_title:'Visitor Gender Distribution'
						},
						store_visitor_age_chart:{
							card_title:'Visitor Age Distribution',
						},
						store_visitor_total_age_gender_chart:{
							card_title:'Gender-Age Distribution'
						},
						store_visitor_weekly_chart:{
							card_title:"Weekday Gender-Age Analysis",
							card_tooltip:"It shows the percentage distribution of the sum of gender-age-specific visiting customers on each weekday for the selected period."
						},
						store_visitor_date_type_chart:{
							card_title:'Gender-Age Analysis',
							card_tooltip:"It shows the total number of visiting customers by gender and age for the selected period."
						},
						store_visitor_holiday_chart:{
							label_weekend:'Weekend',
							label_holiday:'Holiday',
							label_foot_traffic:'Foot Traffic',
							label_visiting:'Visiting',
							card_title:"Holiday-based Inflow Analysis"
						},
						store_visitor_weather_chart:{
							label_foot_traffic:'Foot Traffic',
							label_visiting:'Visiting',
							card_title:"Weather-based Inflow Analysis",
							card_tooltip:"You can analyze the foot traffic and visiting customer changes by comparing the weather conditions to those of yesterday over the past month.",
						},
						store_inflow_weekly_chart:{
							label_direct:"Straight Inflow to the Store",
							card_title:"Weekday Outdoor Advertising Analysis",
							card_tooltip:"You can analyze customers who entered the store through each advertisement to understand the effectiveness of each ad on a weekday basis."
						},
						store_visitor_race_chart:{
							card_title:'Race Distribution'
						},
						store_visitor_race_weekly_chart:{
							card_title:'Weekday Race Analysis'
						},
						store_visitor_race_date_type_chart:{
							card_title:'Daily Race Analysis'
						},
						admin_inflow_total_status_compare:{
							label_total_visitor:'Total Visitor',
							card_title:'Overall Inflow Status',
							card_tooltip:"You can compare the total visiting customers across all stores for the selected and previous periods.",
							label_vs_compare_period:'vs Compare Period'
						},
						admin_inflow_date_compare:{
							card_title:'Inflow Analysis',
							card_tooltip:"You can compare the total visiting customers across all stores on a daily basis for the selected and previous periods."
						},
						admin_inflow_store_ranking_compare:{
							card_title:'Store-Specific Inflow Ranking',
							card_tooltip:"You can view the store-specific ranking of visiting customers for the selected and previous periods in ascending or descending order."
						},
						admin_inflow_age_gender_compare:{
							card_title:'Overall Gender-Age Distribution',
							card_tooltip:"You can compare the gender-age distribution of visiting customers across all stores for the selected and previous periods."
						},
						store_inflow_weekly_chart_compare:{
							card_title:"Weekday Outdoor Advertising Analysis",
							card_tooltip:"You can compare the inflow rates based on outdoor advertising for the selected and previous periods."
						},
						store_visitor_age_gender_day_of_week_compare:{
							card_title:'Weekday Gender-Age Analysis',
							card_tooltip:"You can compare the gender-age distribution of visiting customers on a weekday basis for the selected and previous periods."
						},
						store_visitor_age_gender_compare:{
							card_title:'Gender-Age Distribution',
							card_tooltip:"You can compare the gender-age distribution of visiting customers on a daily basis for the selected and previous periods."
						},
						store_visitor_gender_compare:{
							card_title:'Gender Analysis',
							card_tooltip:"You can compare the gender distribution for the selected and previous periods."
						},
						store_visitor_age_compare:{
							card_title:'Age Compare Analysis',
							card_tooltip:"You can compare the age distribution for the selected and previous periods."
						},
						store_visitor_date_type_chart_compare:{
							card_title:'Gender-Age Analysis',
							card_tooltip:"You can compare the gender-age-specific visiting customer numbers for the selected and previous periods."
						},
						store_visitor_race_compare:{
							card_title:'Overall Race Analysis'
						},
						store_visitor_race_day_of_week_compare:{
							card_title:'Weekday Race Analysis'
						},
						store_visitor_race_date_type_chart_compare:{
							card_title:'Race Analysis'
						}
					},
					distribution:{
						admin_customer_gender_age_multi:{
							tab_purchasing:'Purchasing',
							tab_churning:'Churning',
							card_title_heritage:'Location-Specific Gender-Age Distribution',
							card_title:'Store-Specific Gender-Age Distribution',
							card_tooltip_heritage:"You can view the ranking of customer gender-age for the selected location in ascending or descending order.",
							card_tooltip:"You can view the ranking of customer gender-age for the selected store in ascending or descending order."
						},
						admin_customer_gender_age_select:{
							card_title_heritage:'Location Detail Gender-Age Distribution',
							card_title:'Store Detail Gender-Age Distribution',
							rank_first:'1st',
							rank_second:'2nd',
							rank_third:'3rd'
						},
						admin_customer_status_compare:{
							label_purchasing:'Purchasing',
							label_churning:'Churning',
							card_title:'Total Customer Distribution Status',
							card_tooltip:"You can compare the ratio of total purchasing customers and churned customers across all stores for the selected and previous periods."
						},
						admin_customer_total_status_compare:{
							label_purchasing:'Purchasing',
							label_churning:'Churning',
							card_title:'Total Customer Distribution',
							card_tooltip:"You can compare the total visiting customers on a daily basis for the selected and previous periods."
						},
						admin_customer_ranking:{
							label_purchasing:'Purchasing',
							label_churning:'Churning',
							card_title:"Summary of Customer Distribution Rankings",
							card_tooltip:"You can categorize the average purchasing customers and churned customers across all stores for the selected period as high, medium, and low."
						},
						admin_customer_total_ranking_compare:{
							label_purchasing:'Purchasing',
							label_churning:'Churning',
							card_title:"Store-Specific Customer Type Rankings",
							card_tooltip:"You can compare the ranking of purchasing customers and churned customers for each store in ascending or descending order for the selected and previous periods."
						},
						admin_visitor_age_gender_compare:{
							label_purchasing:'Purchasing',
							label_churning:'Churning',
							card_title:'Gender-Age Specific Distribution',
							card_tooltip:"Selected and previous periods can be compared by converting the overall gender-age sum of all stores into a percentage."
						},
						admin_customer_duration_compare:{
							label_duration:'Dwell Time',
							label_action_person:"Experience Zone Users",
							label_action_area:'Experience Zone',
							card_title:'Dwell Time',
							card_tooltip:"You can compare the total dwell time of all stores for the selected period with the previous period. You can choose to view the data as either the sum or the average."
						},
						admin_customer_duration_ranking_compare:{
							card_title:'Store Specific Dwell Time Ranking',
							card_tooltip:"You can compare the dwell time of each store for the selected period with the previous period, sorting it in ascending or descending order.",
						},
						admin_customer_action_ranking:{
							label_action_duration:'Dwell Time',
							label_action_count:'Users',
							card_title:'Store Specific Experience Zone Usage Ranking',
							card_tooltip:"You can compare the sum of experiential zone usage rates for each store, converted to a percentage, in ascending or descending order, for the selected period and the previous period."
						},
						admin_customer_status:{
							label_purchasing:'Purchasing',
							label_churning:'Churning',
							card_title:'Total Customer Distribution Status',
							card_tooltip:"You can see the ratio of total purchasing customers to churning customers for all stores within the selected period."
						},
						admin_customer_total_status:{
							label_purchasing:'Purchasing',
							label_churning:'Churning',
							card_title:'Total Customer Distribution Status',
							card_tooltip:"You can view the total number of visiting customers on a daily basis for the selected period."
						},
						admin_customer_total_ranking:{
							label_purchasing:'Purchasing',
							label_churning:'Churning',
							label_store_avg:'Total Store Avg of ',
							card_title:'Store Specific Customer Type Ranking',
							card_tooltip:"You can see the ranking of purchase and churn customers for each store within the selected period, both in ascending and descending order."
						},
						admin_customer_gender_age:{
							card_title:'Total Gender-Age Distribution',
							card_tooltip:"You can compare the total gender and age distribution of all stores for the selected period, converted into percentages."
						},
						admin_customer_gender:{
							card_title:'Total Gender Analysis',
							card_tooltip:"You can compare the total gender distribution of all stores for the selected period, converted into percentages."
						},
						admin_customer_age:{
							card_title:'Total Age Analysis',
							card_tooltip:"You can compare the total age distribution of all stores for the selected period, converted into percentages."
						},
						admin_customer_duration:{
							label_duration:'Dwell Time',
							label_action_area:'Experience Zone',
							label_action_usage:'Experience Zone Users',
							card_title:'Dwell Time',
							card_tooltip:"You can view the total dwell time for all stores during the selected period. You can choose to display the data as a sum or average."
						},
						admin_customer_duration_ranking:{
							label_last_avg_duration_pre:'Last ',
							label_last_avg_duration_post:'Days Avg Dwell Time',
							label_duration:'Dwell Time',
							card_title:'Store Specific Dwel Time Ranking',
							card_tooltip:"You can view the dwell time for each store within the selected period in ascending or descending order."
						},
						store_funnel:{
							card_title:'Customer Funnel Analysis',
							card_tooltip:"You can grasp the overall flow from external foot traffic to purchasing customers at a glance.",
							card_tooltip_compare:"You can compare the overall flow between the selected period and the previous period at a glance.",
							label_foot_traffic:'Foot Traffic',
							label_visiting:'Visiting',
							label_purchasing:'Purchasing',
						},
						store_funnel_card:{
							label_all:'All',
							label_compare_period:'Compare Period'
						},
						store_arrow_data:{
							label_compare_period:'Compare Period'
						},
						store_trend_chart:{
							label_purchasing_ratio:'Purchasing Rate',
							label_churning_ratio:'Churning Rate',
							label_purchasing:'Purchasing',
							label_churning:'Churning',
							card_title:'Customer Trend Analysis',
							card_tooltip:"You can check the number of customers among visitors who have led to either a purchase or churn, along with the conversion rate."
						},
						store_time_chart:{
							label_visit_rate:'Visit Rate',
							label_conversion_rate:'Conversion Rate',
							label_churning_rate:'Churning Rate',
							card_title:"Analysis of Visit Rate, Purchase Conversion Rate, and Churn Rate by Time of Day",
							card_tooltip:"You can view statistics for each time slot during the selected period."
						},
						store_age_gender_chart:{
							card_title_purchasing:'Purchasing Customer Gender-Age Analysis',
							card_title_churning:'Churning Customer Gender-Age Analysis',
							card_tooltip:"You can compare the total number of visitors by gender and age during the selected period on a day-by-day basis, expressed as a percentage."
						},
						store_residence:{
							card_title:'Dwell Time Specific Action Conversion Analysis',
							card_tooltip:"Within the set period, you can assess the distribution of customers who meet the specified dwell time, categorizing them into those with behavioral conversions and specifically those who progressed to making a purchase, presented as a total.",
							col_duration_dependency_count:'Customer Count Depends on Dwell Time',
							col_action_conversion:'Action Conversion',
							label_all:'All',
							col_tooltip:"Action conversion refers to spaces that can enhance the value of customer experience, encompassing predefined specific areas.",
							col_tooltip_compare:"You can compare the distribution of customers with specified dwell time who underwent action conversion, including those who proceeded to make a purchase, between the selected period and the previous period.",
							col_purchasing_conversion:'Purchasing Conversion',
							label_compare_period:'Compare Period'
						},
						store_trend_chart_compare:{
							label_purchasing:'Purchasing',
							label_churning:'Churning',
							label_purchasing_rate:'Purchasing Rate',
							label_churning_rate:'Churning Rate',
							card_title:'Customer Trend Analysis',
							card_tooltip:"You can compare the number of purchasing customers and churning customers between the selected period and the previous period."
						},
						store_time_chart_compare:{
							label_visiting_rate:'Visiting Rate',
							label_conversion_rate:'Conversion Rate',
							label_churning_rate:'Churning Rate',
							card_title:'Visiting Rate, Conversion Rate, Churning Rate by Time',
							card_tooltip:"You can compare the visit rate, purchase conversion rate, and churn rate between the selected period and the previous period."
						},
						store_visitor_gender_compare:{
							card_title:'Gender Analysis',
							card_tooltip:"You can compare the gender-based visit count for the selected period and the previous period by day, expressed as a percentage."
						},
						store_visitor_age_compare:{
							card_title:'Age Specific Compare Analysis',
							card_tooltip:"You can compare the visit count by age for the selected period with the previous period."
						}
					},
					heritage:{
						col_visitor:'Visitor',
						col_enter:'Enter',
						col_leave:'Exit',
						col_status:'Status',
						status_in_dwell:'In Dwell',
						status_exit:'Exit',
						label_today_inflow:'Today Inflow',
						label_today_exit:'Today Exit',
						label_in_dwell:'In Dwell',
						card_title:'Visitors'
					},
					area:{
						admin_visitor_category:{
							card_title:'Category Specific Visitor Ranking',
							card_tooltip:"You can view the category ranking of overall store visitors for the selected period in ascending or descending order.",
							card_tooltip_compare:"You can compare the category ranking of overall store visitors for the selected period and the previous period in ascending or descending order.",
							label_target:'Target',
							label_compare:'Compare'
						},
						admin_duration_category:{
							label_target:'Target',
							label_compare:'Compare',
							card_title:'Category Specific Dwell Time Ranking',
							card_tooltip:"You can view the category ranking of overall store dwell time for the selected period in ascending or descending order.",
							card_tooltip_compare:"You can compare the category ranking of overall store dwell time for the selected period with the previous period in ascending or descending order.",
						},
						admin_age_gender_category:{
							label_target:'Target',
							label_compare:'Compare',
							label_visitor:'Visitors',
							label_duration:'Dwell Time',
							card_title:'Category Specific Gender-Age Distribution',
							card_tooltip_compare:"For the top 5 categories based on the number of visitors for the selected period and the previous period, you can compare gender and age in ascending or descending order.",
							card_tooltip:"For the top 5 categories based on the number of visitors for the selected period, you can view gender and age in ascending or descending order."
						},
						store_rank_table:{
							col_category:'Category',
							col_cur_ranking:'Current Rank',
							col_shelf_name:'Shelf Name',
							col_visitors:'Visitors',
							col_duration:'Dwell Time',
							col_last_ranking:'Last Rank',
							card_title_by_visitor:'Ranking by Visitors',
							card_title_by_duration:'Ranking by Dwell Time'
						},
						store_area_analysis_drawer:{
							card_title:'Overall Shelf Analysis',
							tab_visitor_analysis:'Visitor Analysis',
							tab_duration_analysis:'Dwell Time Analysis',
							tab_revenue_analysis:'Revenue Analysis'
						},
						store_visitor_period_chart:{
							label_visitor:'Visitors',
							card_title:'Visitor Analysis',
							card_tooltip:"You can check the number of visitors to the selected display and also view sales and sales quantity together.",
							summary_date:', The highest number of customers visited',
							summary_val_pre:'Total ',
							summary_val_post:' Customers visited',
						},
						store_visitor_hourly_chart:{
							visitor_rate:'Visitor Rate',
							label_visitor:'Visitors',
							card_title:'Visitors By Time',
							card_tooltip:"By time slot, you can view the total and average number of visiting customers to the display, along with the gender and age distribution.",
							summary_hour:', The highest number of customers visited',
							summary_val_pre:'',
							summary_val_post:' Customers visited',
						},
						store_visitor_gender_age_chart:{
							label_highest:'The highest number of customers visited',
							label_lowest:'The lowest number of customers visited',
							card_title:'Visitor Gender-Age Distribution'
						},
						store_visitor_day_of_week_chart:{
							label_sum_visitor:'Sum Visitors',
							label_avg_visitor:'Avg Visitors',
							card_title:"Analysis of Customer Visits by Day of the Week",
							card_tooltip:"By day of the week, you can view the total and average number of customer visits to the display stands, as well as the gender distribution of visitors.",
							label_visitor:'Visitor is ',
							label_summary_count:'',
							summary_post:'Most Visited'
						},
						store_visitor_gender_age_compare_chart:{
							card_title:'Visitor Gender-Age Distribution'
						},
						store_duration_period_chart:{
							label_duration:'Dwell Time',
							card_title:'Shelf Dwell Time',
							card_tooltip:"You can check the dwell time, sales, and sales quantity for the selected display in-store.",
							summary_date:', The longest time of customers visited',
							summary_val_pre:'Stayed for',
							summary_val_post:'minutes.',
						},
						store_duration_hourly_chart:{
							hour_suffix:'',
							label_duration:'Dwell Time',
							card_title:'Dwell Time By Time',
							card_tooltip:"You can view the distribution of dwell time by time slot and the distribution of dwell time by gender and age in total and average for the selected time period.",
							summary_hour:', The Longest time of customers visited',
							summary_val_pre:'Dwell Time is ',
							summary_val_post:' minute',
						},
						store_duration_gender_age_chart:{
							card_title:'Gender-Age Specific Dwell Time Analysis',
							label_longest:'Longest Dwell Time',
							label_shortest:'Shortest Dwell Time'
						},
						store_duration_day_of_week_chart:{
							label_sum_duration:'Sum Dwell Time',
							label_avg_duration:'Avg Dwell Time',
							card_title:"Analysis of Dwell Time by Day of the Week",
							card_tooltip:'You can view the total and average dwell time for each display and gender by day of the week.',
							label_duration:'Dwell Time is',
							label_summary_count:' minute',
							summary_post:'Longest Stayed'
						},
						store_duration_gender_age_compare_chart:{
							card_title:'Gender-Age Specific Dwell Time Analysis'
						}
					},
					flow:{
						flow_sankey:{
							label_limit_5:'Limit 5th',
							label_limit_10:'Limit 10th',
							label_limit_15:'Limit 15th',
							label_limit_20:'Limit 20th',
							card_title:'Flow Analysis',
							switchStand: 'by stand',
							switchCategory: 'by category'
						},
						sankey_node:{
							label_next_category:'Next Moved Category',
							label_moved_person:'Moved Customer Count'
						}
					},
					heatmap:{
						index:{
							label_all:'All',
							card_title:'Heatmap Analysis',
							card_tooltip:"You can see where customers primarily stayed within the selected period, indicated by colors.",
							label_time_filter:'Time Range'
						},
						tab:{
							label_all:'All',
							tab_count:'Visitors',
							tab_duration:'Dwell Time',
							card_title_count:'Shelf Specific Visitors Rank',
							card_title_duration:'Shelf Specific Dwell Time Rank',
							col_ranking:'Ranking',
							col_shelf:'Shelf',
							col_category:'Category',
							col_visiting:'Visiting',
							col_duration:'Dwell Time'
						}
					},
					promotion:{
						index:{
							label_add:'Register New Promotion',
							label_filter:'Filter',
							label_promotion:'Promotion'
						},
						admin_inflow_total:{
							label_last_year:'Last Year',
							card_title:'Total Inflow Status',
							card_tooltip:"You can see the total number of visitors across all stores during the selected promotional period.",
							compare_last_year:'vs Last Year'
						},
						admin_inflow_date:{
							label_visiting:'Visiting',
							label_purchasing:'Purchasing',
							label_conversion_rate:'Conversion Rate',
							card_title:'Customer Analysis',
							card_tooltip:"You can view the total number of visitors across all stores on a daily basis during the selected promotional period.",
							compare_last_year_conversion_rate:'vs Last Year Conversion Rate'
						},
						admin_inflow_ranking:{
							card_title:'Summary of Inflow Rankings',
							card_tooltip:"Summary of Overall Store Inflow Rankings during the Selected Promotion Period"
						},
						admin_inflow_store_ranking:{
							label_avg_visiting:'Avg Visiting',
							label_visiting:'Visiting',
							card_title:"Store-specific Inflow Rankings",
							card_tooltip:"During the selected period, you can view the numerical values of store-specific visit counts in ascending or descending order."
						},
						admin_inflow_gender_age:{
							card_title:'Gender-Age Analysis'
						},
						admin_inflow_gender:{
							card_title:'Gender Analysis',
							card_tooltip:"During the selected promotion period, it shows the gender distribution of visiting customers as a percentage of the total."
						},
						admin_inflow_age:{
							card_title:'Age Analysis',
							card_tooltip:"During the selected promotion period, it shows the age distribution of visiting customers as a percentage of the total."
						},
						admin_inflow_gender_age_multi:{
							card_title:'Store Specific Gender-Age Distribution',
							card_tooltip:"During the selected promotion period, you can view the ranking of gender and age of visiting customers for each store in ascending or descending order."
						},
						admin_inflow_gender_age_select:{
							card_title:'Store Detail Gender-Age Distribution',
							card_tooltip:"During the selected promotion period, the gender and age distribution of customers for each store is presented in percentage."
						},
						admin_inflow_visitor_category:{
							card_title:'Category Specific Visitors',
							card_tooltip:"During the selected promotion period, you can view the categories that had a high number of visits from customers, in ascending or descending order."
						},
						admin_inflow_duration_category:{
							card_title:'Category Specific Dwell Time',
							card_tooltip:"During the selected promotion period, you can view the categories where customers stayed, in ascending or descending order."
						},
						store_inflow_date:{
							label_foot_traffic:'Foot Traffic',
							label_visiting:'Visiting',
							label_purchasing:'Purchasing',
							label_inflow_rate:'Inflow Rate',
							label_conversion_rate:'Conversion Rate',
							card_title:'Customer Analysis',
							card_tooltip:"During the selected promotion period, you can view the total number of store visitors on a daily basis."
						},
						store_inflow_hourly_chart:{
							hour_suffix:'',
							label_foot_traffic:'Foot Traffic',
							label_visiting:'Visiting',
							label_purchasing:'Purchasing',
							label_inflow_rate:'Inflow Rate',
							label_conversion_rate:'Conversion Rate',
							card_title:'Customer Analysis by Time',
							card_tooltip:"During the selected promotion period, you can view the total number of store visitors by hour."
						},
						store_inflow_weekly_chart:{
							card_title:"Customer Analysis by Day of the Week",
							card_tooltip:"You can understand the number of customers visiting the store on each day of the week during the selected promotion period."
						},
						store_area_analysis_content:{
							col_category:'Category',
							col_shelf:'Shelf',
							col_visiting:'Visiting',
							col_duration:'Dwell Time(min)',
							card_title:'Shelf Analysis',
							card_tooltip:"You can view the ranking of displays during the selected promotion period and see the number of customers and the time spent at each display."
						},
						store_visitor_date_type_chart:{
							card_title:'Gender-Age Target Analysis'
						},
						store_visitor_gender_age_index:{
							card_title:'Gender-Age Analysis'
						},
						store_visitor_gender_chart:{
							card_title:'Gender Distribution'
						},
						store_visitor_gender_age_chart:{
							card_title:'Gender-Age Distribution'
						},
						store_visitor_weekly_chart:{
							card_title:"Analysis of gender and age by day of the week"
						},
						manage_search_text_bar:{
							placeholder:'Please search for the promotion name.'
						},
						manage_search_card:{
							label_in_progress:'In Progress',
							label_done:'Done',
							label_latest:'Latest',
							label_oldest:'Oldest',
							label_high_revenue:'High Sales Amount',
							label_low_revenue:'Low Sales Amount'
						},
						manage_search_filter:{
							label_period:'Promotion Period',
							label_status:'Promotion Status'
						},
						manage_promotion_create:{
							label_add:'Add New Promotion'
						},
						manage_promotion_list:{
							label_type:'Type',
							label_period:'Period',
							label_time:'Target Hour',
							label_day_of_week:'Target Day Of Week',
							label_real_revenue:'Real Sales Amount',
							label_target_revenue:'Target Sales Amount',
							label_visitors:'Target Visiting',
							label_gender_age:'Target Gender Age',
							label_gender:'Gender',
							label_age:'Age',
							label_currency_suffix:'($)'
						},
						register_index:{
							err_name:'Please Insert Promotion Name',
							err_type:'Please Insert Type',
							err_period:'Please Insert Period',
							err_store:'Please Select Store',
							err_time:'Unavilable Time Range',
							req_time:'Please Insert Time Range',
							err_day:'Please Select Day Of Week',
							register_success:'Register Success',
							register_fail:'Register Fail',
							modify_success:'Modify Success',
							modify_fail:'Modify Fail'
						},
						promotion_name:{
							label_promotion_name:'Promotion Name',
							label_mandatory:'Mandatory',
							label_type:'Type'
						},
						promotion_multi_store:{
							label_store:'Store',
							label_mandatory:'Mandatory',
							label_available:'Available',
							label_selected:'Selected'
						},
						promotion_range:{
							label_promotion_period:'Promotion Period'
						},
						promotion_date:{
							label_period:'Period'
						},
						promotion_detail_setting:{
							label_detail_setting:'Detail Setting',
							label_on:'On',
							label_off:'Off',
							label_detail:'Options',
							err_check:'Please Check Options',
							label_time_range:'Time Range',
							label_day_of_week_range:'Day Of Week'
						},
						promotion_target_times:{
							err_time:'Unavilable Time Range',
							label_time_range:'Time Range (24Hour)',
							label_start:'Start',
							label_end:'End',
							label_hour_suffix:''
						},
						promotion_target_days:{
							label_day_of_week:'Day Of Week'
						},
						promotion_target_show:{
							label_promotion_time:'Promotion Time',
							label_promotion_day_of_week:'Promotion Day Of Week'
						},
						promotion_target_sales:{
							label_target_sales_setting:'Target Sales Setting',
							label_pos:'(Link POS)',
							label_target_sales:'Target Sales Amount',
							label_currency_suffix:'$'
						},
						promotion_target_customer:{
							label_promotion_target:'Promotion Target',
							label_visiting:'Visiting',
							label_gender:'Gender',
							label_age:'Age',
							label_gender_age:'Gender-Age'
						},
						promotion_button:{
							label_save:'Save',
							label_modify:'Modify',
							label_cancel:'Cancel'
						}
					},
					report: {
						excel:'Excel',
						pdf:'PDF',
						modalTitle: '{{modalTitle, customReportModalTitle}}',
						report_header:'Monthly/Weekly report',
						report_desc: '* On the 1st of each month, a monthly report is generated for the previous month',
						no_data:'There are no reports available for download.',
						visitors: 'Visitors',
						desc: 'Counter analysis',
						funnel: 'Funnel analysis',
						month_1: 'January',
						month_2: 'February',
						month_3: 'March',
						month_4: 'April',
						month_5: 'May',
						month_6: 'June',
						month_7: 'July',
						month_8: 'August',
						month_9: 'September',
						month_10: 'October',
						month_11: 'November',
						month_12: 'December',
						title: 'Recent Report',
						file_name: 'File name',
						download: 'Download',
						data_set:'Data is being aggregated.',
						set_desc: 'Please wait while the file is being generated.',
						monthlyReport: 'Monthly Report',
						weeklyDataRangeHeader: "Data Period (Monday to Sunday)",
						compareDataRangeHeader: "Compare (Monday to Sunday)",
						weeklyHelper: '* A report for the previous 7 days (Monday to Sunday) is generated every Monday.',
						weeklyCompareLabel:"Compare",
						weeklyCompareBefore:"Last Week",
						weeklyCompareBeforeMonth:"Last Month, Same Week",
						weeklyCompareBeforeYear:"Last Year, Same Month, Same Week",
						monthDataTableFilename:"{{month, customMonth}} {{year}}, Monthly Report",
						weeklyDataTableFilname:"{{month, customMonth}} {{year}} Week {{weekNum}}, Weekly Report"
					}
				}
			}
		},
		interpolation: {
			format: (value, format) => {
				if (format === 'customMonth') {
					return moment().month(value - 1).format('MMMM')
				}
				if (format === 'customReportModalTitle') {
					if (value === 'excel') {
						return 'Excel'
					}
					return 'PDF'
				}
				return value
			}
		}
	})

export default i18n