
function loadIfdo(){
	var _NB_gs = 'wlog.ifdo.co.kr';
	var _NB_MKTCD = 'NDA2241785650';
	var _NB_APPVER=''; /* 하이브리드 앱 버전 */
	(function(a,b,c,d,e){
		var f
		f = b.createElement(c)
		var g = b.getElementsByTagName(c)[0]
		f.async=1;
		f.src=d;
		f.setAttribute('charset','utf-8');
		g.parentNode.insertBefore(f,g)
	})(window,document,'script','//script.ifdo.co.kr/jfullscript.js');
}

export default loadIfdo